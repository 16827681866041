import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/header"
import TwoAndOne from "../../components/twoAndOne"
const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Medienabhängigkeit" />
      <Header siteTitle="Beratung" subTitle="Medienabhängigkeit" />
      <TwoAndOne>
        <div className="content">
          <p>
            Surfen, chatten, gamen, News posten oder einfach nur mal schnell ein
            „Schnäppchen“ schlagen, von zu Hause, per Computer, zu jeder Tages-
            und Nachtzeit – kein Problem. Fast in jedem Haushalt steht ein PC,
            mit oder ohne Internetanschluss. Die schier unendlichen
            Möglichkeiten der virtuellen Welt üben eine besondere Faszination
            auf Menschen unterschiedlichster Altersgruppen aus und auch aus der
            Arbeitswelt ist der PC nicht mehr wegzudenken.
          </p>
          <p>
            Nicht immer bleibt es beim Mediengebrauch bei Arbeit, Spaß und
            Freizeitvergnügen. Für immer mehr Menschen wird der PC zum zentralen
            Lebensmittelpunkt und das reale Leben verblasst hinter dem
            virtuellen. Aus anfänglichem Vergnügen wird ein unwiderstehlicher
            Drang.
          </p>
          <p>
            <b>Für Betroffene bedeutet das:</b>
          </p>
          <ul>
            <li>Die Nutzung wird immer häufiger, länger, intensiver.</li>
            <li>Reale Freundeskontakte brechen weg.</li>
            <li>Beziehungskontakte gibt es (fast) nur noch im Internet.</li>
            <li>Die Leistungsfähigkeit in Schule und Beruf lässt nach.</li>
            <li>
              Wenn der PC nicht zur Verfügung steht, treten innere Unruhe,
              Depressionen oder Aggressionen auf.
            </li>
            <li>
              Ständiger Stress und Streit mit Angehörigen über die PC-Nutzung.
            </li>
            <li>
              Trotz der negativen Konsequenzen ändert sich nichts am eigenen
              Verhalten.
            </li>
          </ul>
          <b>Für Angehörige bedeutet das:</b>
          <ul>
            <li>
              Der Betroffene nimmt nicht mehr an gemeinsamen Aktivitäten teil.
            </li>
            <li>Gemeinsame Gespräche enden oft in Eskalation.</li>
            <li>Ständiger Streit über gebrochene Nutzungsvereinbarungen.</li>
            <li>Sorgen über die persönliche Entwicklung des Betroffenen.</li>
            <li>Gefühle von Hilflosigkeit und Ohnmacht, Scham oder Schuld.</li>
          </ul>
          <div className="notification is-warning">
          Sollten Sie sich oder einen Angehörigen in dieser Beschreibung wiederfinden oder hat der Selbsttest eine entsprechende Auswertung ergeben, sollten Sie sich in einem persönlichen Gespräch beraten lassen.
          </div>
        </div>
        <div className="content">
          <div className="notification is-primary">
            <h4 className="title">Weiterführende Informationen</h4>
            <p>
              Informationen zu Chancen, Risiken & Faszination
              <br />
              <a href='http://www.bundespruefstelle.de' target='_blank'>
                <span className="icon is-small">
                  <i className="fas fa-external-link-alt"></i>
                </span>
                <span> www.bundespruefstelle.de</span>
              </a>
            </p>
            <p>
              Die EU-Initiative für mehr Sicherheit im Netz
              <br />
              <a href='http://www.klicksafe.de' target='_blank'>
                <span className="icon is-small">
                  <i className="fas fa-external-link-alt"></i>
                </span>
                <span> www.klicksafe.de</span>
              </a>
            </p>
            <p>
              Sicherheit in mobilen Netzen (Ein Angebot der Landesanstalt für
              Medien NRW)
              <br />
              <a href='http://www.handysektor.de' target='_blank'>
                <span className="icon is-small">
                  <i className="fas fa-external-link-alt"></i>
                </span>
                <span> www.handysektor.de</span>
              </a>
            </p>
          </div>
        </div>
      </TwoAndOne>
    </Layout>
  )
}

export default IndexPage
